<template>
  <b-modal v-model="c_show" title="Two Factor Verification" hide-footer>
    <el-alert title="Info" effect="dark" :closable="false" type="info">
      <p>To help you protect your account, we are recommend you to enable two-factor authentication.<br/><br/>
        1. Download Google Authenticator or Microsoft Authenticator on Play Store or App Store.<br/>
        2. Scan QR with the apps you have downloaded.<br/>
        3. Input OTP code generated on the apps.
      </p>
    </el-alert>
    <div class="row">
      <div class="col-12 text-center">
        <img :src="qr_image" style="align-items: center;"/>
      </div>
      <div class="col-12">
        <el-input size="small" v-model="form.otp_code" placeholder="Input OTP code generated on the app." maxlength="6" show-word-limit/>
        <b-form-invalid-feedback :state="!v$.form.otp_code.$invalid" v-if="v$.form.otp_code.$errors[0]">
          {{ v$.form.otp_code.$errors[0].$message }}
        </b-form-invalid-feedback>
      </div>
    </div>
    <div class="d-flex flex-row float-right mt-2">
      <el-button :disabled="v$.form.$error" @click="submitEnableTwoFactor" size="small" type="primary" class="ml-2">{{ $t('general.submit') }}</el-button>
      <el-button @click="onCancel" size="small" type="secondary">{{ $t('general.cancel') }}</el-button>
    </div>
  </b-modal>
</template>
<script>
import {
  required, numeric, minLength, maxLength,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import authAPI from '../../api/auth';
import popupErrorMessages from '../../library/popup-error-messages';

export default {
  name: 'TwoFactorSetup',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        otp_code: {
          required,
          numeric,
          minLength: minLength(6),
          maxLength: maxLength(6),
        },
      },
    };
  },
  data() {
    return {
      c_show: false,
      qr_image: '',
      form: {
        otp_code: '',
      },
    };
  },
  methods: {
    async getQrSetup() {
      const response = await authAPI.init_2fa();
      this.qr_image = response.data.qr;
    },
    async submitEnableTwoFactor() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      this.$parent.showLoader();
      const response = await authAPI.verify_enabled_2fa({
        code: this.form.otp_code,
      });
      this.$parent.hideLoader();
      await popupErrorMessages(response).then(() => {
        this.$store.dispatch('auth/doLogout').then(() => {
          this.$message({
            message: this.$t('auth.success.setup_twofac'),
            type: 'success',
          });
          this.$router.push('/auth/login');
        });
      });
      console.log(response);
    },
    onCancel() {
      this.$emit('onCancel');
    },
  },
  watch: {
    show() {
      this.c_show = this.show;
      if (this.show) {
        this.getQrSetup();
        this.v$.form.$touch();
      }
    },
  },
};
</script>
